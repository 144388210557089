<template>
  <TableWrapper class="table-data-view table-responsive" v-if="!tradingLoading">
    <a-table :dataSource="dataSourceDeals" :columns="columnsDeals" :pagination="false">
      <template #сmd="record">
        <p :class="`_${record.record.cmd} cmd`">
          {{ i18n.t('accounts.trading.' + record.record.cmd) }}
        </p>
      </template>
      <template #profit="record">
        <p class="profit">
          <span
            :class="{
              _sell: record.record.profit.amount < 0,
              _success: record.record.profit.amount > 0,
              _change: record.record.profit.amount == 0,
            }"
          >
            {{ record.record.profit.amount }}
          </span>
          {{ record.record.profit.currency }}
        </p>
      </template>
    </a-table>
    <div style="text-align: right; margin-bottom: 20px">
      <a-pagination
        :default-page-size="pageSize"
        v-model:pageSize="pageSize"
        :show-size-changer="true"
        v-model:current="dealsMeta.current"
        :total="dealsMeta.count"
        @change="onChangePage"
      >
        <template #buildOptionText="props">
          <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
        </template>
      </a-pagination>
    </div>
  </TableWrapper>
  <sdCards headless v-else>
    <a-skeleton active :paragraph="{ rows: 10 }" />
  </sdCards>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { TableWrapper } from '@/view/styled';

export default {
  components: { TableWrapper },
  emits: ['reloadTable'],

  setup(props, { emit }) {
    const i18n = useI18n();
    const { state } = useStore();
    const pageSize = ref(10);

    const dealsData = computed(() => state.tradingAccounts.dealsData);
    const dealsMeta = computed(() => state.tradingAccounts.dealsMeta);
    const tradingLoading = computed(() => state.tradingAccounts.loading);

    const columnsDeals = computed(() => [
      {
        title: i18n.t('accounts.trading.cmd'),
        dataIndex: 'cmd',
        key: 'cmd',
        slots: { customRender: 'сmd' },
      },
      {
        title: i18n.t('accounts.trading.volume'),
        dataIndex: 'volume',
        key: 'volume',
      },
      {
        title: i18n.t('accounts.trading.symbol'),
        dataIndex: 'symbol',
        key: 'symbol',
      },
      {
        title: i18n.t('accounts.trading.price'),
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: i18n.t('accounts.trading.profit'),
        dataIndex: 'profit',
        key: 'profit',
        slots: { customRender: 'profit' },
      },
      {
        title: i18n.t('accounts.trading.commission'),
        dataIndex: 'commission',
        key: 'commission',
      },
      {
        title: i18n.t('accounts.trading.create_time'),
        dataIndex: 'create_time',
        key: 'create_time',
      },
      {
        title: '',
        width: 0,
      },
    ]);
    const dataSourceDeals = computed(() =>
      dealsData.value
        ? dealsData.value.map((deal, key) => {
            const { id, attributes } = deal;
            const { symbol, cmd, commission, volume, create_time, price, profit } = attributes;
            return {
              key: key + 1,
              id,
              symbol,
              cmd,
              commission: `${+commission.amount} ${commission.currency}`,
              volume: +volume,
              create_time: i18n.d(new Date(create_time), 'long'),
              price: `${+price.amount} ${price.currency}`,
              profit,
            };
          })
        : [],
    );

    const onChangePage = (page) => {
      emit('reloadTable', page);
    };

    return {
      i18n,
      columnsDeals,
      dataSourceDeals,
      dealsMeta,
      tradingLoading,
      pageSize,
      onChangePage,
    };
  },
};
</script>

<style scoped lang="scss">
._sell {
  color: #ff4d4f;
}
._success {
  color: #20c997;
}
._buy {
  color: #2c9aea;
}
._change {
  color: #808080;
}
.profit {
  display: flex;
  span {
    margin-right: 5px;
  }
}
</style>
